import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import deepCopy from '../../helpers/deepCopy';
import { FiltersData } from '../../types/filters';

const initialCustomFilters = {
  isSynced: {
    label: 'Synced',
    checked: false,
    value: 'isSynced',
  },
  isNotSynced: {
    label: 'Not synced',
    checked: false,
    value: 'isNotSynced',
  },
};

export const useSynchedFilters = (
  setIsSynced: Dispatch<SetStateAction<boolean | undefined>>,
) => {
  const [synchedFilters, setSynchedFilters] = useState<FiltersData>(
    deepCopy(initialCustomFilters),
  );

  const handleClearSynchedFilters = useCallback(
    () => setSynchedFilters(deepCopy(initialCustomFilters)),
    [],
  );

  const handleApplySynchedFilters = useCallback(() => {
    setSynchedFilters((prev) => {
      const filters = Object.keys(prev).find(
        (option) => prev?.[option]?.checked,
      );
      switch (filters) {
        case 'isSynced':
          setIsSynced(true);
          break;
        case 'isNotSynced':
          setIsSynced(false);
          break;
        default:
          setIsSynced(undefined);
      }
      return deepCopy(prev);
    });
  }, [setIsSynced]);

  return {
    setSynchedFilters,
    synchedFilters,
    onClearSynchedFilters: handleClearSynchedFilters,
    onApplySynchedFilters: handleApplySynchedFilters,
  };
};
