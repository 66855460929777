import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { black54, white } from 'view/theme/colors';

export const StyledFiltersContainer = styled(Box)`
  flex-wrap: nowrap;
  min-width: 22rem;
  //max-width: 30.5rem;
  padding: 0 1.5rem;
  max-height: 460px;
  overflow: auto;
  .MuiButton-root.MuiButton-outlined {
    height: 3rem;
  }
  .clear-filters {
    font-size: 0.85rem;
    color: ${black54};
    padding-right: 0.5rem;
    & svg {
      width: 1.25rem;
    }
    &:hover {
      background-color: ${white};
    }
  }
  .search-input-box .MuiOutlinedInput-root {
    height: 3rem;
  }
  .MuiFormControl-root button {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
` as typeof Box;
