import React, { ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import deepCopy from 'helpers/deepCopy';
import { useTranslation } from 'react-i18next';
import StickyBox from 'react-sticky-box';
import { FiltersData } from 'types/filters';
import Button from 'view/components/Button';
import FocusIcon from 'view/icons/FocusIcon';
import TrashIcon from 'view/icons/Trash';
import { black, black16, black54, black87, purple } from 'view/theme/colors';

import Input from '../Input';

import { StyledFiltersContainer } from './styled';

type FiltersProps = {
  data: FiltersData;
  onClear: () => void;
  setFilters: (
    data: FiltersData | ((prev: FiltersData) => FiltersData),
  ) => void;
  onApply: (data: FiltersData) => void;
  searchTagsValue: string;
  setSearchTagsValue: (value: string) => void;
  buttonLabel?: string;
  placeholder?: string;
  tagCreateAllowed?: boolean;
  width?: number;
  customFilters?: FiltersData;
  setCustomFilters?: Dispatch<SetStateAction<FiltersData>>;
  customFiltersTitle?: string;
};

const TagFilters = ({
  data,
  onClear,
  setFilters,
  onApply,
  searchTagsValue,
  setSearchTagsValue,
  buttonLabel,
  placeholder,
  tagCreateAllowed = false,
  width = 0,
  customFilters,
  setCustomFilters,
  customFiltersTitle,
}: FiltersProps) => {
  const { t } = useTranslation();
  const onSearchTags = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    setSearchTagsValue(search);
    if (data) {
      setFilters((prev) => {
        Object.keys(prev).forEach((option) => {
          prev[option].hidden = !prev[option].label.includes(
            search.toLowerCase(),
          );
        });
        return deepCopy(prev);
      });
    }
  };

  const handleClickTag = (optionKey: string) => {
    setFilters((prev) => {
      if (optionKey in prev) {
        prev[optionKey].checked = !prev[optionKey].checked;
        return deepCopy(prev);
      }
      prev[optionKey] = {
        label: optionKey,
        value: optionKey,
        checked: true,
        hidden: false,
      };
      return deepCopy(prev);
    });
  };

  const { visibleTags, visibleCustomFilters } = useMemo(
    () => ({
      visibleTags: Object.keys(data).filter((option) => !data[option].hidden),
      visibleCustomFilters:
        customFilters &&
        Object.keys(customFilters).filter(
          (option) => !customFilters[option].hidden,
        ),
    }),
    [customFilters, data],
  );

  const handleClickCustomFilter = (optionKey: string) => {
    setCustomFilters?.((prev) => {
      prev[optionKey].checked = !prev[optionKey].checked;
      Object.keys(prev).forEach((option) => {
        if (option === optionKey) return;
        prev[option].checked = false;
      });
      return deepCopy(prev);
    });
  };

  const isTagCreateShown = useMemo(
    () =>
      searchTagsValue &&
      searchTagsValue.length >= 3 &&
      !visibleTags.some((i) => i === searchTagsValue),
    [visibleTags, searchTagsValue],
  );

  return (
    <StyledFiltersContainer width={width < 30 * 16 ? '30rem' : `${width}px`}>
      <StickyBox style={{ background: 'white', zIndex: 5 }}>
        <Box
          mb={2}
          display="flex"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle1" sx={{ color: black }}>
            {t('filtering.byTags')}:
          </Typography>
          <Button
            label={t('filtering.clearAll')}
            color="inherit"
            className="clear-filters"
            variant="text"
            endIcon={<TrashIcon color={black54} />}
            onClick={onClear}
          />
        </Box>
        <Box mb={4} className="search-input-box" position="relative">
          <Input
            onChange={onSearchTags}
            value={searchTagsValue}
            placeholder={placeholder || t('filtering.addYourTags') || ''}
            endAdornment={
              tagCreateAllowed && isTagCreateShown ? (
                <Box
                  onClick={() => handleClickTag(searchTagsValue)}
                  color={purple}
                  fontWeight={400}
                  display="flex"
                  alignItems="center"
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  <FocusIcon />
                </Box>
              ) : (
                <></>
              )
            }
          />
        </Box>
      </StickyBox>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl
          sx={{ marginBottom: '2rem' }}
          component="fieldset"
          variant="standard"
        >
          <FormGroup
            sx={{ display: 'flex', flexDirection: 'row', rowGap: '0.75rem' }}
          >
            {visibleTags.map((option: string, index) => {
              const currentOpt = data[option];
              if (currentOpt.hidden) {
                return false;
              }
              return (
                <FormControlLabel
                  sx={{ margin: 0, columnGap: '0.75rem' }}
                  key={option}
                  control={
                    <Box>
                      <Button
                        sx={{
                          borderColor: currentOpt.checked ? purple : black16,
                          color: currentOpt.checked ? purple : black87,
                        }}
                        variant="outlined"
                        label={currentOpt.label}
                        onClick={() => handleClickTag(option)}
                      />
                    </Box>
                  }
                  label={''}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Box>
      <StickyBox bottom>
        {customFilters && (
          <Box bgcolor="white" pt={1} pb={2}>
            <Typography variant="subtitle1" sx={{ color: black, pb: 1 }}>
              {customFiltersTitle}:
            </Typography>
            <FormControl
              sx={{ display: 'flex', flexDirection: 'row' }}
              component="fieldset"
              variant="standard"
            >
              {visibleCustomFilters?.map((option: string, index) => {
                const currentOpt = customFilters[option];
                return (
                  <FormControlLabel
                    sx={{ margin: 0, columnGap: '0.75rem' }}
                    key={option}
                    control={
                      <Box>
                        <Button
                          sx={{
                            borderColor: currentOpt.checked ? purple : black16,
                            color: currentOpt.checked ? purple : black87,
                          }}
                          variant="outlined"
                          label={currentOpt.label}
                          onClick={() => handleClickCustomFilter(option)}
                        />
                      </Box>
                    }
                    label={''}
                  />
                );
              })}
            </FormControl>
          </Box>
        )}
        <Box pb={2} bgcolor="white">
          <Button
            type="submit"
            label={buttonLabel || t('filtering.applyTags')}
            withLoader
            fullWidth
            onClick={() => onApply(data)}
            // loading={submitting}
          />
        </Box>
      </StickyBox>
    </StyledFiltersContainer>
  );
};

export default TagFilters;
