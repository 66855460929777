import React, { FC } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { SimpleStory, StoryStatus } from '../../../types/stories';
import PopOver from '../PopOver';
import SimpleStoryCardRowControls from '../SimpleStoryCardRow/SimpleStoryCardRowControls';

interface SimpleStoryCardRowDropdownControlsProps {
  story: SimpleStory;
  onDelete?: (id?: string) => void;
}
export const SimpleStoryCardRowDropdownControls: FC<
  SimpleStoryCardRowDropdownControlsProps
> = ({ story, onDelete }) => {
  return (
    <PopOver
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      content={() => {
        return (
          <Box
            className="controls"
            minWidth={120}
            borderRadius="8px"
            sx={{
              '.clone-device-button, .edit-device-button, .delete-device-button':
                {
                  py: 1,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  },
                },
            }}
          >
            <SimpleStoryCardRowControls
              withText
              creatorId={story.creator.id}
              storyId={story.storyId}
              onDelete={onDelete}
              isEditShown={
                story.status === StoryStatus.draft ||
                story.status === StoryStatus.rejected
              }
              containerProps={{
                direction: 'column',
                spacing: 1,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                width: '100%',
              }}
            />
          </Box>
        );
      }}
    >
      {({ onOpen, onClose, isOpen }) => {
        const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          const cb = isOpen ? onClose : onOpen;
          cb(e);
        };
        return (
          <IconButton onClick={handleOpen}>
            <MoreVertIcon />
          </IconButton>
        );
      }}
    </PopOver>
  );
};
