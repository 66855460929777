import React, { FC, useCallback } from 'react';

import Box from '@mui/material/Box';
import { SimpleStory, StoryStatus } from 'types/stories';
import { ROLES_NAMES } from 'types/users';
import useRole from 'view/components/Role/useRole';
import URobotIcon from 'view/icons/URobot';
import { blue } from 'view/theme/colors';

import { handleOpenSimpleStoriesDrawer } from './SimpleStoriesDrawer';

interface RobotIconProps {
  story: SimpleStory;
}

const RobotIcon: FC<RobotIconProps> = ({ story }) => {
  const isDeviceReadyStoryVisible = useRole({
    allowedRoles: [
      ROLES_NAMES.admin,
      ROLES_NAMES.moderator,
      ROLES_NAMES.storyComposer,
      ROLES_NAMES.robotHandler,
    ],
  });
  const handleClickRobot = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();
      if (!isDeviceReadyStoryVisible) return;
      handleOpenSimpleStoriesDrawer(story);
    },
    [isDeviceReadyStoryVisible, story],
  );

  if (story.status !== StoryStatus.published) return null;

  return (
    <Box
      display="flex"
      height="1.25rem"
      alignItems="center"
      ml="auto"
      onClick={handleClickRobot}
    >
      <URobotIcon color={blue} />
    </Box>
  );
};

export default RobotIcon;
