import { useMemo } from 'react';

import { DeviceReadyStory } from '../../types/stories';

export const useGetDrsBackgroundImage = (drs?: DeviceReadyStory) => {
  return useMemo(() => {
    if (!drs) return '';
    const bgImage =
      drs?.backgroundImage?.previewUrl || drs?.backgroundImage?.url;

    const contentImage = (Array.isArray(drs?.content) ? drs?.content : [])
      ?.filter((d) => d?.image?.attachment?.data?.url)
      ?.map((d) => ({
        url: d.image?.attachment?.data?.url,
        previewUrl: d.image?.attachment?.data?.previewUrl,
      }));

    const firstContentImage = contentImage?.find((d) => d.url);

    return (
      bgImage || firstContentImage?.previewUrl || firstContentImage?.url || ''
    );
  }, [drs]);
};
