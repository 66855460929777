import * as React from 'react';
import { FC } from 'react';

import Pagination from '@mui/material/Pagination';
import MuiTablePagination from '@mui/material/TablePagination';
import { black40, black87 } from 'view/theme/colors';

import useDidUpdate from '../../../hooks/useDidUpdate';

interface TablePaginationActionsProps {
  currentPage?: number;
  itemsPerPage?: number;
  totalPages?: number;
  totalItems?: number;
  itemCount?: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

const TablePagination: FC<TablePaginationActionsProps> = ({
  totalItems = 0,
  itemCount = 0,
  totalPages = 0,
  itemsPerPage = 0,
  currentPage = 0,
  onPageChange,
}) => {
  const hidePrevButton = totalPages === 1 || currentPage === 1;
  const hideNextButton = totalPages === 1 || currentPage === totalPages;

  useDidUpdate(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  if (totalPages <= 1) return null;
  if (!itemCount) return null;

  return (
    <MuiTablePagination
      sx={{
        width: '100%',
        display: 'flex',
        '.MuiTablePagination-toolbar': {
          padding: 0,
          justifyContent: 'space-between',
          display: 'flex',
          flex: 1,
        },
        '.MuiTablePagination-spacer': { display: 'none' },
        padding: 0,
        borderBottom: 'none',
      }}
      rowsPerPageOptions={[0]}
      component={'div'}
      onPageChange={() => null}
      count={totalItems}
      rowsPerPage={itemsPerPage}
      page={currentPage - 1}
      ActionsComponent={() => (
        <Pagination
          sx={{
            '.MuiPagination-ul': { justifyContent: 'end' },
            '.MuiPaginationItem-root': {
              color: black40,
              '&.Mui-selected': {
                color: black87,
              },
            },
          }}
          hidePrevButton={hidePrevButton}
          hideNextButton={hideNextButton}
          count={totalPages}
          page={currentPage}
          onChange={onPageChange as any}
        />
      )}
    />
  );
};

export default TablePagination;
