import React, { FC, useCallback } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { formatDate, LocaleKeyType } from 'helpers/date-time';
import useFetchListDataWithFilteringAndSorting from 'hooks/useFetchListDataWithFilteringAndSorting';
import useRefetchIfDrsPublishing from 'hooks/useRefetchIfDrsPublishing';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  getSharedDeviceAccess,
  shareBulkDeviceAccess,
} from 'services/api/devices';
import { ApiGetListParams } from 'services/api/types';
import { DeviceReadyStory } from 'types/stories';
import Button from 'view/components/Button';
import DeviceReadyStoryCardRow from 'view/components/DeviceReadyStoryCardRow';
import FilterSortBlock from 'view/components/FilterSortBlock/FilterSortBlock';
import MainContainer from 'view/components/MainContainer';
import MainLayout from 'view/components/MainLayout';
import MultiselectCheckboxGroup, {
  useCheckBoxContext,
} from 'view/components/MultiselectCheckboxGroup';
import NoSearchResultsWrapper from 'view/components/NoSearchResultsWrapper';
import TablePagination from 'view/components/TablePagination';
import HaruRobotIcon from 'view/icons/HaruRobot';
import { black, white } from 'view/theme/colors';

interface DeviceDetailPageSidebarProps {
  open: boolean;
  onClose: () => void;
  name: string;
  updatedDate?: string;
  drsId?: string;
  onRefetch?: () => void;
}

export const DeviceDetailPageSidebar: FC<DeviceDetailPageSidebarProps> = ({
  onClose,
  open,
  name,
  updatedDate,
  drsId = '',
  onRefetch,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t, i18n } = useTranslation();
  const { checkedArray, handleUncheckAll } = useCheckBoxContext();

  const handleFetchStories = useCallback(
    (params: ApiGetListParams) => {
      if (open) {
        return getSharedDeviceAccess(drsId, {
          ...params,
          showOnlyAvailable: true,
        });
      }
    },
    [drsId, open],
  );

  const {
    data,
    sorting,
    onSetSorting,
    onSetTags,
    onSetSearchQuery,
    onChangePage,
    onRefetch: onRefetchStories,
    fetchLoading,
    isFiltersApplied,
  } = useFetchListDataWithFilteringAndSorting<DeviceReadyStory>(
    handleFetchStories,
    { disabledQueryParams: true },
  );

  useRefetchIfDrsPublishing(data?.items || [], onRefetchStories);

  const handleAttachStories = async () => {
    if (!drsId) return;
    await shareBulkDeviceAccess(drsId, checkedArray);
    onRefetch?.();
    onClose();
    handleUncheckAll();
    enqueueSnackbar(t('notifications.successfullyAdded'), {
      variant: 'success',
    });
  };

  const screenWidth = window.innerWidth;
  const drawerWidth =
    screenWidth > 1538 ? screenWidth * 0.75 : screenWidth - 70;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: 1000,
        },
        '& .MuiDrawer-paper': {
          overflow: 'unset',
          width: '100%',
          maxWidth: drawerWidth,
        },
      }}
    >
      <MainContainer
        height="100%"
        overflow="scroll"
        width="100%"
        maxWidth={drawerWidth}
      >
        <MainLayout flexDirection="column" pb={18}>
          <Box
            position="absolute"
            right={-20}
            top={46}
            p={1}
            onClick={onClose}
            sx={{ cursor: 'pointer', borderRadius: '4px' }}
            bgcolor={white}
            display="flex"
            alignItems="center"
          >
            <CloseIcon fontSize="medium" sx={{ color: black }} />
          </Box>
          <Box display="flex" alignItems="center">
            <HaruRobotIcon size={112} />
            <Box pl={2} display="flex" flexDirection="column">
              <Typography variant="h1">
                {t('devicesListPage.addDeviceReadyStory') || ''}
              </Typography>
              <Box display="flex" alignItems="flex-start" pt={2}>
                <Typography variant="body2">{name}</Typography>
                <Typography variant="body2" px={1}>
                  /
                </Typography>
                {updatedDate && (
                  <Typography variant="body2">
                    {formatDate(
                      new Date(updatedDate),
                      'MMM dd, yyyy',
                      i18n.language as LocaleKeyType,
                    )}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box pt={4}>
            <FilterSortBlock
              setTags={onSetTags}
              setSearchQuery={onSetSearchQuery}
              sorting={sorting}
              setSorting={onSetSorting}
              // isSortShown={false}
            />
            {/*<StickyBox style={{ zIndex: 2 }}>*/}
            <Box
              pb={1}
              zIndex={5000}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bgcolor={white}
            >
              <MultiselectCheckboxGroup.MultiselectCheckboxGroupTitle
                title={t('devicesListPage.deviceReadyStoriesList')}
              />
              <MultiselectCheckboxGroup.MultiselectCheckboxHeadItem
                label={t('selectAll')!}
                labelPlacement="start"
                items={data?.items || []}
                keyExtractor="id"
                sx={{ pr: 3 }}
              />
            </Box>
            {/*</StickyBox>*/}
            <NoSearchResultsWrapper
              isLoading={fetchLoading}
              title={t('Device Ready Stories')}
              isFiltersApplied={isFiltersApplied}
            >
              {(data?.items || []).map((item) => (
                <DeviceReadyStoryCardRow
                  hoverOff
                  deviceReadyStory={item}
                  isBaseControlsShown={false}
                  key={item.id}
                  controls={
                    <Box display="flex" alignItems="center">
                      <MultiselectCheckboxGroup.MultiselectCheckboxItem
                        id={item?.id || ''}
                        classNameOnUnchecked="controls"
                      />
                    </Box>
                  }
                />
              ))}
            </NoSearchResultsWrapper>
          </Box>
        </MainLayout>
        <Box
          position="fixed"
          bottom={0}
          maxWidth={drawerWidth}
          width="100%"
          zIndex={6}
        >
          <MainLayout bgcolor="white" mb="-2px">
            <TablePagination {...data?.meta} onPageChange={onChangePage} />
          </MainLayout>
          <Divider />
          <MainLayout justifyContent="flex-end" py={2.5} bgcolor={white}>
            <Button
              variant="text"
              label={t('devicesListPage.cancel') || ''}
              onClick={onClose}
              sx={{ mr: 3 }}
            />
            <Button
              disabled={!checkedArray.length}
              label={t('devicesListPage.addToDevice') || ''}
              onClick={handleAttachStories}
            />
          </MainLayout>
        </Box>
      </MainContainer>
    </Drawer>
  );
};
