import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography, { TypographyProps } from '@mui/material/Typography';

interface TextWithPopoverProps extends TypographyProps {
  text?: string;
  tooltipText?: string;
}

const TextWithPopover: FC<PropsWithChildren<TextWithPopoverProps>> = ({
  text,
  sx,
  tooltipText,
  children,
  ...restProps
}) => {
  const textRef = useRef<HTMLSpanElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [text]);

  return (
    <Tooltip
      title={tooltipText ?? text}
      arrow
      disableHoverListener={!isTruncated}
    >
      <Typography
        ref={textRef}
        noWrap
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', ...sx }}
        {...restProps}
      >
        {children || text}
      </Typography>
    </Tooltip>
  );
};

export default TextWithPopover;
