import React, { lazy } from 'react';

import Box from '@mui/material/Box';
import { StoryCommentsContextProvider } from 'contexts/story-comments';
import { Navigate, Outlet } from 'react-router-dom';

import { ROLES_NAMES } from '../types/users';
import ProtectedRoute from '../view/components/ProtectedRoute';
import { DeviceDetailPage } from '../view/pages/devices/DeviceDetailPage';

import {
  ACCEPT_INVITATION_PATH,
  ACCOUNT_SETTINGS_BASE_INFO_PATH,
  ACCOUNT_SETTINGS_CHANGE_PASSWORD_PATH,
  ACCOUNT_SETTINGS_INVITE_PATH,
  APP_PATH,
  DEVICE_READY_STORY_PATH_CREATE,
  DEVICES_PATH,
  FORGOT_PASSWORD_PATH,
  FORGOT_PASSWORD_REQUEST_PATH,
  HOME_PATH,
  LIBRARY_PATH,
  SIGNIN_PATH,
  SIGNUP_CONFIRMATION_PATH,
  // SIGNUP_PATH,
  STORIES_PATH_CREATE,
  STORIES_PATH_EDIT,
  STORIES_PATH_VIEW,
} from './routes';

const AcceptInvitation = lazy(
  () => import('../view/pages/auth/AcceptInvitation'),
);

const RecoverPasswordRequest = lazy(
  () => import('../view/pages/auth/RecoverPasswordRequest'),
);
const RecoverPassword = lazy(
  () => import('../view/pages/auth/RecoverPassword'),
);
const AnonymousRoute = lazy(
  () => import('../view/components/routing/AnonymousRoute'),
);
const SignIn = lazy(() => import('../view/pages/auth/SignIn'));
// const SignUp = lazy(() => import('../view/pages/auth/SignUp'));
const SignUpConfirmation = lazy(
  () => import('../view/pages/auth/SignUpConfirmation'),
);
const ResponsiveAppBar = lazy(() => import('../view/components/AppBar'));
const AdminRoute = lazy(() => import('../view/components/routing/AdminRoute'));
const BaseInfo = lazy(() => import('../view/pages/account/BaseInfo'));
const ChangePassword = lazy(
  () => import('../view/pages/account/ChangePassword'),
);
const InviteUsers = lazy(() => import('../view/pages/account/InviteUsers'));
const HomeAll = lazy(() => import('../view/pages/home/HomeAll'));
const LibraryList = lazy(() => import('../view/pages/library/LibraryList'));

const CreateSimpleStory = lazy(
  () => import('../view/pages/stories/CreateSimpleStory'),
);
const ViewSimpleStory = lazy(
  () => import('../view/pages/stories/ViewSimpleStory'),
);

const CreateDeviceReadyStory = lazy(
  () => import('../view/pages/stories/DeviceReadyStory'),
);

const DevicesList = lazy(() => import('../view/pages/devices/DevicesList'));

export interface RouteInterface {
  path?: string;
  element: React.JSX.Element;
}

export interface RouteWithRoutesInterface {
  element: React.JSX.Element;
  routes: RouteInterface[];
}

const routesConfig: (RouteInterface | RouteWithRoutesInterface)[] = [
  { path: FORGOT_PASSWORD_REQUEST_PATH, element: <RecoverPasswordRequest /> },
  { path: FORGOT_PASSWORD_PATH, element: <RecoverPassword /> },
  {
    element: (
      <AnonymousRoute>
        <Outlet />
      </AnonymousRoute>
    ),
    routes: [
      { path: SIGNIN_PATH, element: <SignIn /> },
      // { path: SIGNUP_PATH, element: <SignUp /> },
      { path: SIGNUP_CONFIRMATION_PATH, element: <SignUpConfirmation /> },
      { path: ACCEPT_INVITATION_PATH, element: <AcceptInvitation /> },
      { path: '*', element: <Navigate to={SIGNIN_PATH} /> },
    ],
  },
  {
    element: (
      <AdminRoute>
        <Box display="flex">
          <ResponsiveAppBar />
          <Outlet />
        </Box>
      </AdminRoute>
    ),
    routes: [
      { path: APP_PATH, element: <Navigate to={HOME_PATH} /> },
      { path: '*', element: <Navigate to={APP_PATH} /> },
      { path: ACCOUNT_SETTINGS_BASE_INFO_PATH, element: <BaseInfo /> },
      {
        path: ACCOUNT_SETTINGS_CHANGE_PASSWORD_PATH,
        element: <ChangePassword />,
      },
      {
        path: ACCOUNT_SETTINGS_INVITE_PATH,
        element: (
          <ProtectedRoute allowedRoles={[ROLES_NAMES.admin]}>
            <InviteUsers />
          </ProtectedRoute>
        ),
      },
      { path: `${HOME_PATH}/:type?`, element: <HomeAll /> },
      { path: `${LIBRARY_PATH}/:type?`, element: <LibraryList /> },
    ],
  },
  { path: APP_PATH, element: <Navigate to={HOME_PATH} /> },

  {
    element: (
      <AdminRoute>
        <Box display="flex">
          <ResponsiveAppBar />
          <Outlet />
        </Box>
      </AdminRoute>
    ),
    routes: [
      {
        path: STORIES_PATH_CREATE,
        element: (
          <ProtectedRoute allowedRoles={[ROLES_NAMES.storyCreator]}>
            <StoryCommentsContextProvider isSimpleStory={true}>
              <CreateSimpleStory />
            </StoryCommentsContextProvider>
          </ProtectedRoute>
        ),
      },
      {
        path: STORIES_PATH_EDIT + '/:id',
        element: (
          <StoryCommentsContextProvider isSimpleStory={true}>
            <CreateSimpleStory />
          </StoryCommentsContextProvider>
        ),
      },
      {
        path: STORIES_PATH_VIEW + '/:id',
        element: (
          <StoryCommentsContextProvider isSimpleStory={true}>
            <ViewSimpleStory />
          </StoryCommentsContextProvider>
        ),
      },
      {
        path: DEVICE_READY_STORY_PATH_CREATE,
        element: <CreateDeviceReadyStory />,
      },
      {
        path: DEVICES_PATH,
        element: (
          <ProtectedRoute
            allowedRoles={[ROLES_NAMES.admin, ROLES_NAMES.robotHandler]}
          >
            <DevicesList />
          </ProtectedRoute>
        ),
      },
      {
        path: DEVICES_PATH + '/:id',
        element: (
          <ProtectedRoute
            allowedRoles={[ROLES_NAMES.admin, ROLES_NAMES.robotHandler]}
          >
            <DeviceDetailPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

export default routesConfig;
