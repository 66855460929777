import React, { FC, MouseEvent } from 'react';

import IosShareIcon from '@mui/icons-material/IosShare';
import { SxProps, TypographyProps } from '@mui/material';
import Box from '@mui/material/Box/Box';
import Typography from '@mui/material/Typography';
import eventBuss, { EventBussEvent } from 'eventBuss';
import { useTranslation } from 'react-i18next';
import { black54 } from 'view/theme/colors';

interface ShareDeviceFromCardProps {
  sx?: SxProps;
  drsId: string;
  onClick?: () => void;
  color?: string;
  TextProps?: TypographyProps;
  IconProps?: any;
}

export const ShareDeviceFromCard: FC<ShareDeviceFromCardProps> = ({
  sx,
  drsId,
  onClick,
  color = black54,
  TextProps,
  IconProps,
}) => {
  const { t } = useTranslation();
  const handleOpenModal = () =>
    eventBuss.dispatch(EventBussEvent.openDeviceSidebar, drsId);

  const handleShareDevice = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    handleOpenModal();
    onClick?.();
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        px={2}
        sx={{ cursor: 'pointer', ...sx }}
        onClick={handleShareDevice}
        className="share-device"
      >
        <IosShareIcon sx={{ color }} {...IconProps} />
        <Typography ml={1} variant="body2" sx={{ color }} {...TextProps}>
          {t('hoverButtons.share')}
        </Typography>
      </Box>
    </>
  );
};
