import React, { FC, useCallback, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import eventBuss, { EventBussEvent } from 'eventBuss';
import { useSubscribeEventBuss } from 'eventBuss/useSubscribeEventBuss';
import { SimpleStory } from 'types/stories';
import CardRowImage from 'view/components/CardRowImage';
import TagsList from 'view/components/TagsList';
import URobotIcon from 'view/icons/URobot';
import ViewSimpleStoryDrs from 'view/pages/stories/ViewSimpleStory/components/ViewSimpleStoryDrs';
import ViewSimpleStoryUserInfo from 'view/pages/stories/ViewSimpleStory/components/ViewSimpleStoryUserInfo';
import { black, white } from 'view/theme/colors';

import TextWithPopover from '../../../components/TextWithPopOver';

const SimpleStoriesDrawer: FC = () => {
  const [open, setOpen] = useState(false);
  const [simpleStory, setSimpleStory] = useState<SimpleStory | undefined>();

  const handleCloseDrawer = () => {
    setOpen(false);
  };
  const handleOpenDrawer = () => setOpen(true);

  const handleToggleDrawer = useCallback((story: SimpleStory) => {
    handleOpenDrawer();
    setSimpleStory(story);
  }, []);

  useSubscribeEventBuss(EventBussEvent.openSSSidebar, handleToggleDrawer);

  const imgUrl = simpleStory?.imageObjects?.[0]?.previewUrl || '';

  if (!simpleStory) return null;

  const screenWidth = window.innerWidth;
  const drawerWidth =
    screenWidth > 1538 ? screenWidth * 0.75 : screenWidth - 70;

  return (
    <Drawer
      id="simple-story-drawer"
      open={open}
      onClose={handleCloseDrawer}
      sx={{
        '& .MuiDrawer-paper': {
          overflow: 'unset',
          width: '100%',
          maxWidth: drawerWidth,
        },
      }}
    >
      <Box
        px={8}
        pt={8}
        position="relative"
        height="100%"
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={drawerWidth}
      >
        <Box
          position="absolute"
          right={-20}
          top={46}
          p={1}
          onClick={handleCloseDrawer}
          sx={{ cursor: 'pointer', borderRadius: '4px' }}
          bgcolor={white}
          display="flex"
          alignItems="center"
        >
          <CloseIcon fontSize="medium" sx={{ color: black }} />
        </Box>
        <Box display="flex" alignItems="center" height="7rem">
          <Box mr={3}>
            <CardRowImage
              width="7rem"
              height="7rem"
              imgUrl={imgUrl}
              title={simpleStory?.title || ''}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            overflow="hidden"
          >
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              position="relative"
            >
              <TextWithPopover
                width="90%"
                fontSize={32}
                text={simpleStory.title}
              />
              <Box ml="auto">
                <URobotIcon width={'2.3rem'} height={'2.3rem'} />
              </Box>
            </Box>
            <ViewSimpleStoryUserInfo
              name={simpleStory.creator.username}
              createdOn={simpleStory.createdAt}
            />
            <TagsList tags={simpleStory.tags} />
          </Box>
        </Box>
        <Box mt={8}>
          <ViewSimpleStoryDrs
            simpleStory={simpleStory}
            hoverOff
            isRobotIconShown={false}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export const handleOpenSimpleStoriesDrawer = (story: SimpleStory) =>
  eventBuss.dispatch(EventBussEvent.openSSSidebar, story);

export default SimpleStoriesDrawer;
