import { Dispatch, FC, SetStateAction } from 'react';

import Box from '@mui/material/Box';
import { Order } from 'services/api/types';

import { FiltersData } from '../../../types/filters';

import { FilterSortBlockFilter } from './FilterSortBlockFilter';
import { FilterSortBlockSearch } from './FilterSortBlockSearch';
import { FilterSortBlockSort } from './FilterSortBlockSort';

interface FilterSortBlockProps {
  setTags: Dispatch<SetStateAction<string[]>>;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  sorting: [string, Order];
  setSorting: Dispatch<SetStateAction<[string, Order]>>;
  isFilterShown?: boolean;
  isSortShown?: boolean;
  isSearchShown?: boolean;
  customFilters?: FiltersData;
  setCustomFilters?: Dispatch<SetStateAction<FiltersData>>;
  onApplyCustomFilters?: () => void;
  onCleanCustomFilters?: () => void;
  customFiltersTitle?: string;
}

const FilterSortBlock: FC<FilterSortBlockProps> = ({
  setTags,
  setSearchQuery,
  sorting,
  setSorting,
  isFilterShown = true,
  isSortShown = true,
  isSearchShown = true,
  customFilters,
  setCustomFilters,
  onApplyCustomFilters,
  onCleanCustomFilters,
  customFiltersTitle,
}) => {
  return (
    <Box sx={{ width: '100%', flexDirection: 'row' }} my={2} display="flex">
      {isFilterShown && (
        <Box mr={4} width="13.5rem">
          <FilterSortBlockFilter
            setTags={setTags}
            customFilters={customFilters}
            setCustomFilters={setCustomFilters}
            onApplyCustomFilters={onApplyCustomFilters}
            onCleanCustomFilters={onCleanCustomFilters}
            customFiltersTitle={customFiltersTitle}
          />
        </Box>
      )}
      {isSortShown && (
        <Box mr={3}>
          <FilterSortBlockSort sorting={sorting} setSorting={setSorting} />
        </Box>
      )}
      {isSearchShown && (
        <FilterSortBlockSearch setSearchQuery={setSearchQuery} />
      )}
    </Box>
  );
};

export default FilterSortBlock;
