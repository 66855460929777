import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';

import useTags from 'hooks/useTags';
import { useTranslation } from 'react-i18next';
import { FiltersData } from 'types/filters';

import FiltersSelect from '../FiltersSelect';
import TagFilters from '../TagFilters';

interface FilterSortBlockFilterProps {
  setTags: Dispatch<SetStateAction<string[]>>;
  customFilters?: FiltersData;
  setCustomFilters?: Dispatch<SetStateAction<FiltersData>>;
  onApplyCustomFilters?: () => void;
  onCleanCustomFilters?: () => void;
  customFiltersTitle?: string;
}

export const FilterSortBlockFilter: FC<FilterSortBlockFilterProps> = ({
  setTags,
  customFilters,
  setCustomFilters,
  onApplyCustomFilters,
  onCleanCustomFilters,
  customFiltersTitle,
}) => {
  const { t } = useTranslation();

  const {
    tags: filters,
    setTags: setFilters,
    onClearSelectedTags: handleClearSelectedFilters,
    onGetSelectedTags: handleGetSelectedFilters,
  } = useTags();

  const [searchTagsValue, setSearchTagsValue] = useState('');

  const handleApplyTags = useCallback(() => {
    setTags(handleGetSelectedFilters());
    onApplyCustomFilters?.();
  }, [handleGetSelectedFilters, onApplyCustomFilters, setTags]);

  const handleClearFilters = useCallback(() => {
    handleClearSelectedFilters();
    onCleanCustomFilters?.();
  }, [handleClearSelectedFilters, onCleanCustomFilters]);

  const handleOnClickCleanTagButton = useCallback(() => {
    handleClearFilters();
    handleApplyTags();
  }, [handleApplyTags, handleClearFilters]);

  return (
    <FiltersSelect
      label={t('filtering.filtersBy') || ''}
      filters={filters}
      customFilters={customFilters}
      setFilters={setFilters}
      setCustomFilters={setCustomFilters}
      handleOnClickCleanTagButton={handleOnClickCleanTagButton}
      menu={
        <TagFilters
          buttonLabel={t('filtering.applyFilters') || ''}
          data={filters}
          onClear={handleClearFilters}
          setFilters={setFilters}
          onApply={handleApplyTags}
          searchTagsValue={searchTagsValue}
          setSearchTagsValue={setSearchTagsValue}
          placeholder={t('filtering.searchByTagName') || ''}
          customFilters={customFilters}
          setCustomFilters={setCustomFilters}
          customFiltersTitle={customFiltersTitle}
        />
      }
    />
  );
};
