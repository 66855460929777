import React, { FC, MouseEvent, useCallback, useMemo } from 'react';

import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { DeviceReadyStory, DRSPackageStatus, StoryStatus } from 'types/stories';
import { red } from 'view/theme/colors';

import eventBuss, { EventBussEvent } from '../../../eventBuss';
import { DEVICES_PATH } from '../../../routes/routes';
import { syncDeviceReadyStoryToRdmp } from '../../../services/api/stories/device-ready-stories';
import { Tooltip } from '../Tooltip';

import { DeviceReadyStoryCardRowStatusIcon } from './DeviceReadyStoryCardRowStatusIcon';

interface DeviceReadyStoryCardRowStatusProps {
  drsPackageStatus: DeviceReadyStory['drsPackage'];
  drsStatus: DeviceReadyStory['status'];
  onRefetch?: () => void;
  drsId: string;
}

const excludedPagesForSync = [DEVICES_PATH];

const DeviceReadyStoryCardRowStatus: FC<DeviceReadyStoryCardRowStatusProps> = ({
  drsPackageStatus,
  drsStatus,
  drsId,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [shownError, setShownError] = React.useState(false);

  const handleSync = useCallback(async () => {
    try {
      await syncDeviceReadyStoryToRdmp(drsId);
      eventBuss.dispatch(EventBussEvent.drsPublishing);
    } catch (error) {
      console.error(error);
    }
  }, [drsId]);

  const handleShowError = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShownError(true);
  }, []);

  const handleHideError = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setShownError(false);
  }, []);

  const content = useMemo(() => {
    if (
      drsStatus === StoryStatus.published &&
      !drsPackageStatus &&
      !excludedPagesForSync.every((page) => location.pathname.includes(page))
    )
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          flex={1}
        >
          <DeviceReadyStoryCardRowStatusIcon
            text={t('sync') as string}
            onClick={handleSync}
          />
        </Box>
      );

    if (!drsPackageStatus) return null;
    const status = drsPackageStatus.status;
    switch (status) {
      case DRSPackageStatus.succeeded: {
        return (
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-start"
            flex={1}
            height="100%"
          >
            <DeviceReadyStoryCardRowStatusIcon
              containerProps={{
                borderRadius: '4px',
                px: 1.5,
                py: 0.5,
              }}
              text={t('synced') as string}
              textProps={{
                fontSize: 14,
                ml: 1,
              }}
              iconSize={14}
            />
          </Box>
        );
      }
      case DRSPackageStatus.inProgress: {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flex={1}
          >
            <DeviceReadyStoryCardRowStatusIcon
              icon={<CircularProgress size={24} />}
              text={t('sync') as string}
            />
          </Box>
        );
      }
      case DRSPackageStatus.failed: {
        return (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flex={1}
            zIndex={10}
          >
            <Tooltip
              sx={{ zIndex: 11 }}
              onMouseEnter={handleShowError}
              onMouseLeave={handleHideError}
              onClick={handleShowError}
              open={shownError}
              arrow
              title={drsPackageStatus?.buildError}
            >
              <ReportGmailerrorredOutlinedIcon sx={{ mr: 3, fill: red }} />
            </Tooltip>
            <DeviceReadyStoryCardRowStatusIcon
              text={t('sync') as string}
              onClick={handleSync}
            />
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  }, [
    drsPackageStatus,
    drsStatus,
    handleHideError,
    handleShowError,
    handleSync,
    location.pathname,
    shownError,
    t,
  ]);

  return (
    <Box display="flex" alignItems="center" flex={1} mx={2} height="100%">
      {content}
    </Box>
  );
};

export default DeviceReadyStoryCardRowStatus;
