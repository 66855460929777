import { FC, PropsWithChildren } from 'react';

import Box from '@mui/material/Box';
import { BoxProps } from '@mui/system';
import { NavLink } from 'react-router-dom';

interface NavLinkWrapperProps extends BoxProps {
  to: string;
}

export const NavLinkWrapper: FC<PropsWithChildren<NavLinkWrapperProps>> = ({
  children,
  to,
  sx,
  ...props
}) => {
  return (
    <Box
      component={NavLink}
      to={to}
      onClick={(e: any) => {
        e.stopPropagation();
        return;
      }}
      sx={{ textDecoration: 'none', ...sx } as any}
      {...(props as any)}
    >
      {children}
    </Box>
  );
};
