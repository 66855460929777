import React, { FC, useCallback } from 'react';

import { Badge } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useFetchListDataWithFilteringAndSorting from 'hooks/useFetchListDataWithFilteringAndSorting';
import useRefetchIfDrsPublishing from 'hooks/useRefetchIfDrsPublishing';
import { useTranslation } from 'react-i18next';
import StickyBox from 'react-sticky-box';
import { getPublishedDeviceReadyStories } from 'services/api/stories/device-ready-stories';
import { ApiGetListParams, PaginatedResponse } from 'services/api/types';
import { DeviceReadyStory, SimpleStory } from 'types/stories';
import DeviceReadyStoryCardRow from 'view/components/DeviceReadyStoryCardRow';
import TablePagination from 'view/components/TablePagination';
import URobotIcon from 'view/icons/URobot';
import { black, black54 } from 'view/theme/colors';

import ViewSimpleStoryDrsSort from './ViewSimpleStoryDrsSort';

interface ViewSimpleStoryDrsProps {
  simpleStory?: SimpleStory | null;
  hoverOff?: boolean;
  isRobotIconShown?: boolean;
}

const ViewSimpleStoryDrs: FC<ViewSimpleStoryDrsProps> = ({
  simpleStory,
  hoverOff,
  isRobotIconShown = true,
}) => {
  const { t } = useTranslation();
  const handleFetchDeviceReadyStories = useCallback(
    async (params: ApiGetListParams) =>
      getPublishedDeviceReadyStories(params, simpleStory?.storyId || ''),
    [simpleStory?.storyId],
  );

  const {
    data,
    onChangePage,
    onRefetch,
    onSetLanguages,
    onSetStatuses,
    isFiltersApplied,
    isFetched,
  } = useFetchListDataWithFilteringAndSorting<DeviceReadyStory>(
    handleFetchDeviceReadyStories,
    { disabledQueryParams: true },
  );
  const items: DeviceReadyStory[] = data?.items || [];

  const meta: PaginatedResponse<DeviceReadyStory>['meta'] = data?.meta!;

  useRefetchIfDrsPublishing(items, onRefetch);

  return (
    <Box width="100%" height="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        {!!items.length && (
          <Box display="flex" alignItems="center">
            {isRobotIconShown && (
              <Box mr="1rem">
                <URobotIcon color={black} width={'1.5rem'} height={'1.5rem'} />
              </Box>
            )}
            <Badge
              badgeContent={meta?.totalItems}
              color="primary"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Typography fontSize={24}>{t('drsList')}</Typography>
            </Badge>
          </Box>
        )}
        <Box display="flex" alignItems="center">
          {!isFiltersApplied && !items.length ? null : (
            <ViewSimpleStoryDrsSort
              onSetLanguages={onSetLanguages}
              onSetStatuses={onSetStatuses}
            />
          )}
        </Box>
      </Box>
      {!items.length && isFetched && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={19}
          flexDirection="column"
        >
          <URobotIcon color={black54} width={'3.3rem'} height={'3.3rem'} />
          <Typography fontSize={20} mt={3}>
            {t('noResults.noDrs')}
          </Typography>
          <Typography fontSize={14} fontWeight={500} color={black54}>
            {t('noResults.noDrsDescription', {
              title: simpleStory?.title,
            })}
          </Typography>
        </Box>
      )}
      {items.map((item) => (
        <DeviceReadyStoryCardRow
          deviceReadyStory={item as DeviceReadyStory}
          isStatusShown
          key={item.id}
          hoverOff={hoverOff}
          onDelete={onRefetch}
        />
      ))}
      <StickyBox bottom style={{ zIndex: 10 }}>
        <TablePagination {...data?.meta} onPageChange={onChangePage} />
      </StickyBox>
    </Box>
  );
};

export default ViewSimpleStoryDrs;
