import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { formatDate, LocaleKeyType } from 'helpers/date-time';
import { useTranslation } from 'react-i18next';
import { STORIES_PATH_VIEW } from 'routes/routes';
import { SimpleStory } from 'types/stories';
import RobotIcon from 'view/pages/home/components/RobotIcon';
import { black16, purple, white } from 'view/theme/colors';

import Avatar from '../Avatar';
import { SimpleStoryCardRowDropdownControls } from '../CardRowControls/SimpleStoryCardRowDropdownControls';
import CardRowImage from '../CardRowImage';
import { NavLinkWrapper } from '../NawLinkWrapper';
import TagsList from '../TagsList';

export const StyledCard = styled(Box)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 2rem;
  transition: box-shadow 0.25s ease-in-out;
  border: 1px solid transparent;
  background: ${white};
  border-radius: 1rem;
  margin: 0 0 0.5rem 0;
  cursor: pointer;
  max-height: 96px;

  &:hover {
    background: ${white};
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
    //.controls {
    //  display: block;
    //}
  }
  .image-container img {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 0.6rem;
  }
  .subtitle-block svg {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.4rem;
  }

  //.controls {
  //  transition: opacity 0.2s ease-in-out;
  //  display: none;
  //}
`;

type CardProps = {
  story: SimpleStory;
  onDelete?: (id?: string) => void;
};

const SimpleStoryCardRow = ({ story, onDelete }: CardProps) => {
  const { t, i18n } = useTranslation();

  const imgUrl = story?.imageObjects?.[0]?.previewUrl || '';

  return (
    <NavLinkWrapper to={`${STORIES_PATH_VIEW}/${story.storyId}`}>
      <StyledCard>
        <Box display="flex" alignItems="center" width="600px">
          <CardRowImage imgUrl={imgUrl} title={story?.title} sx={{ mr: 2 }} />
          <Box>
            <Box display="flex" alignItems="center" mb={0.25}>
              <Typography variant="body1" noWrap maxWidth={500}>
                {story?.title}
              </Typography>
            </Box>

            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              className="subtitle-block"
            >
              {story?.creator && (
                <Avatar
                  username={story?.creator?.username || story?.creator?.email}
                  size={1}
                  sx={{ width: '1.5rem', height: '1.5rem' }}
                />
              )}
              <Typography variant="body2" noWrap>
                {story?.creator.username}
              </Typography>
              <Typography color={black16} variant="body2">
                /
              </Typography>
              <Typography variant="body2">
                {formatDate(
                  story.createdAt,
                  'MMM dd, yyyy',
                  i18n.language as LocaleKeyType,
                )}
              </Typography>
              <Typography color={black16} variant="body2">
                /
              </Typography>
              <Typography
                variant="body2"
                textTransform="capitalize"
                sx={{
                  wordBreak: 'break-all',
                }}
              >
                {t(`storyStatus.${story.status?.toLowerCase()}`)}
              </Typography>
              <Typography color={black16} variant="body2">
                /
              </Typography>
              {story.minimumAge && (
                <Typography variant="body2" color={purple}>
                  {story.minimumAge}+
                </Typography>
              )}
            </Stack>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          overflow="hidden"
          justifyContent="flex-start"
          flex={1}
        >
          <TagsList
            tags={story.tags}
            visibleTags={2}
            isAllowedToExpand={false}
          />
        </Box>
        <Box ml={2}>
          <RobotIcon story={story} />
        </Box>
        <Box textAlign="right" className="controls" display="flex">
          <SimpleStoryCardRowDropdownControls
            story={story}
            onDelete={onDelete}
          />
        </Box>
      </StyledCard>
    </NavLinkWrapper>
  );
};

export default SimpleStoryCardRow;
