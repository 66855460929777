import React, { FC } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { DeviceReadyStory, StoryStatus } from '../../../types/stories';
import { ROLES_NAMES } from '../../../types/users';
import DeviceReadyStoryCardRowControls from '../DeviceReadyStoryCardRowControls';
import PopOver from '../PopOver';
import useRole from '../Role/useRole';

interface DeviceReadyStoryCardRowDropdownControlsProps {
  deviceReadyStory: DeviceReadyStory;
  onDelete?: (id?: string) => void;
  onCopy?: (id?: string) => void;
}

export const DeviceReadyStoryCardRowDropdownControls: FC<
  DeviceReadyStoryCardRowDropdownControlsProps
> = ({ deviceReadyStory, onCopy, onDelete }) => {
  const status = deviceReadyStory.status;

  const isAllowedDeviceButton = useRole({
    allowedRoles: [ROLES_NAMES.robotHandler],
  });

  return (
    <PopOver
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      content={({ onClose }) => {
        return (
          <Box
            className="controls"
            py={1}
            minWidth={120}
            borderRadius="8px"
            onClick={(e) => e.stopPropagation()}
            sx={{
              '.share-device, .clone-device-button, .edit-device-button, .delete-device-button':
                {
                  py: 1,
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  },
                },
            }}
          >
            <DeviceReadyStoryCardRowControls
              onClosePopOver={onClose}
              storyId={deviceReadyStory.id}
              creatorId={deviceReadyStory?.creator?.id}
              originalBasicStoryId={deviceReadyStory.originalBasicStoryId}
              onDelete={onDelete}
              onCopy={onCopy}
              isEditShown={
                status === StoryStatus.draft || status === StoryStatus.rejected
              }
              isShareDeviceShown={
                status === StoryStatus.published && isAllowedDeviceButton
              }
            />
          </Box>
        );
      }}
    >
      {({ onOpen, onClose, isOpen }) => {
        const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          e.preventDefault();
          const cb = isOpen ? onClose : onOpen;
          cb(e);
        };
        return (
          <IconButton onClick={handleOpen}>
            <MoreVertIcon />
          </IconButton>
        );
      }}
    </PopOver>
  );
};
