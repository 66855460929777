import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { HOME_PATH } from 'routes/routes';

const useGetAttributeAndNavigate = (attribute: string) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const tabs = document.querySelectorAll(`[data-testid^=${attribute}]`);
    if (tabs.length > 0) {
      const somePathTypes: string[] = [];
      const somePath: string[] = [];

      tabs.forEach((tab) => {
        const path = tab.getAttribute('data-testid')?.split(attribute)[1];
        if (path) {
          somePathTypes.push(path.split('/')?.[2]);
          somePath.push(path);
        }
      });

      const currentPathType = window.location.pathname.split('/')?.[2];
      const currentPath = window.location.pathname;

      if (
        currentPathType !== undefined &&
        !somePathTypes.includes(currentPathType)
      ) {
        navigate(HOME_PATH + location.search, { replace: true });
      }

      const foundLink = somePath.find((path) => path === currentPath);
      if (foundLink) {
        setIsReady(true);
        return navigate(`${foundLink}${location.search}`, { replace: true });
      }
      const firstAttribute = tabs[0].getAttribute('data-testid');
      const link = firstAttribute?.split(attribute)[1];
      if (link) {
        setIsReady(true);
        return navigate(`${link}${location.search}`, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isReady;
};

export default useGetAttributeAndNavigate;
